<template>
    <div class="order-list">
        <vx-card>
            <vue-bootstrap4-table
                    :rows="data"
                    :columns="columns"
                    :config="config"
                    :actions="actions"
                    :classes="{table: 'row-select'}"
                    @on-change-query="onChangeQuery"
                    @on-change-per-page="onChangePerPage"
                    :total-rows="total_rows"
                    @on-select-row="onSelectRows"
                    @on-unselect-row="onSelectRows"
                    @on-all-unselect-rows="onSelectRows"
                    @on-all-select-rows="onSelectRows"
                    columnSelection>

                <template slot="status" slot-scope="props">
                    <span class="vbt-table-td" v-if="!props.row.type.is_cancellation">
                        <span v-if="getParentStatus(props.row.status)" v-html="orderStatusFormatter(getParentStatus(props.row.status).label)"></span>
                        <span v-html="orderStatusFormatter(props.row.status)"></span>
                        <router-link :to="'/invoices/view/'+props.row.reffered_by.id" class="mg-label"
                                     v-if="props.row.reffered_by !== null">{{props.row.reffered_by.type.name}} ></router-link>
                    </span>
                    <span class="vbt-table-td" v-else>
                    </span>
                </template>

                <template slot="positions" slot-scope="props">
                    <ul v-if="props.row.items.length >= 1">
                        <li v-for="item in props.row.items"><small>{{item.product_name}} <b>[{{item.amount}}x {{item.unit_price_netto}}€ = {{item.sum_price_netto}}€]</b></small></li>
                    </ul>
                    <span v-if="props.row.items.length == 0">-</span>
                </template>


                <template slot="table_tools_left">
                    <v-select
                            v-model="selectedOrderType"
                            :options="orderTypes"
                            autowidth
                            label="name"
                            class="w-1/3"
                            :searchable="false"
                            placeholder="Belegtyp filtern"
                            @input="changeOrderType">
                    </v-select>
                </template>

                <template slot="overdue" slot-scope="props">
                    <span class="vbt-table-td">
                        <span v-html="overDueDateFormatter(props.row.overdue_date, props.row.status)"
                              v-if="props.row.reffered_by === null"></span>
                    </span>
                </template>

                <template slot="invoice_number" slot-scope="props">
                    <span class="vbt-table-td">
                        <router-link :to="'/invoices/view/'+props.row.id" v-if="props.row.invoice_number">{{props.row.invoice_number}}</router-link>
                    </span>
                </template>


                <template slot="row_actions" slot-scope="props">
                    <div class="column-actions-group">
                        <vx-tooltip text="Bearbeiten" position="left">
                            <router-link  v-if="showEditButton(props.row.status)"  :to="'/invoices/'+props.row.id">
                                <vs-button color="success" type="filled" icon="edit"
                                           size="small"
                                           :to="'/invoices/'+props.row.id"></vs-button>
                            </router-link>
                        </vx-tooltip>

                        <vx-tooltip text="Einsehen" position="left">
                            <router-link  v-if="!showEditButton(props.row.status)"  :to="'/invoices/view/'+props.row.id">
                                <vs-button color="warning" type="filled"
                                           icon="search" size="small"
                                           :to="'/invoices/view/'+props.row.id"></vs-button>
                            </router-link>
                        </vx-tooltip>

                        <vx-tooltip text="Als bezahlt markieren" position="left">
                            <vs-button
                                    v-if="!showEditButton(props.row.status) && props.row.status != 3 && props.row.reffered_by === null && !props.row.type.is_cancellation"
                                    color="danger" type="filled"
                                    icon="euro_symbol" size="small"
                                    @click="openPayDatePrompt(props.row.id)"></vs-button>
                        </vx-tooltip>

                        <vx-tooltip text="PDF Download" position="left">
                            <vs-button v-if="!showEditButton(props.row.status)" color="dark" type="border"
                                       icon="get_app"
                                       size="small"
                                       target="_blank"
                                       :href="getDocumentUrl(props.row)" ></vs-button>
                        </vx-tooltip>
                        <vx-tooltip text="Duplizieren & bearbeiten" position="left">
                            <vs-button color="dark" type="border"
                                       v-if="!props.row.type.is_cancellation"
                                       icon="content_copy" size="small"
                                       :to="'/invoices/duplicate/'+props.row.id"></vs-button>
                        </vx-tooltip>


                        <vx-tooltip text="Stornorechnung einsehen" position="left">
                            <vs-button v-if="props.row.reffered_by !== null" color="dark" type="filled"
                                       icon="forward" size="small"
                                       :to="'/invoices/view/'+props.row.reffered_by.id"></vs-button>
                        </vx-tooltip>

                    </div>
                </template>

                <template slot="empty-results">
                    Keine Rechnungen gefunden.
                </template>

                <template slot="table-last-row">
                    <tr class="vbt-row-summary" v-if="userHasPermission('edit_open_orders')">
                        <td colspan="2">
                            <span class="vbt-table-td">{{total_rows}} Ergebnisse</span>
                        </td>
                        <td colspan="4" align="right"></td>
                        <td colspan="2">
                            <span class="vbt-table-td"><b>Gesamt: {{pageSum}}</b></span>
                        </td>
                    </tr>
                </template>

            </vue-bootstrap4-table>
        </vx-card>

        <div v-if="selectedData.length >= 1">
            <vs-button color="success" class="ml-5 mt-3" @click="mergeDocuments">Markierte Rechnungen herunterladen</vs-button>
            <vs-button color="success" class="ml-5 mt-3" @click="csvExportOrders">Markierte Rechnungen als CSV exportieren</vs-button>
        </div>

        <pay-date-prompt :order-id="payDatePromptOrderId" :paid-status-id="paidStatusId" :active="activePayedPrompt"
                         @close="onPayDatePromptClose"></pay-date-prompt>
    </div>
</template>

<script>
    import VueBootstrap4Table from '@/plugins/vue-bootstrap4-table-master/src/components/VueBootstrap4Table'

    import ApiService from "../../../api";

    import moment from 'moment';
    import QueryHelper from "../../../mixins/helper/query.helper";
    import * as qs from "qs";
    import PriceHelper from "../../../mixins/helper/price.helper";
    import PayDatePrompt from "../../../components/invoice/PayDatePrompt";
    import vSelect from 'vue-select'
    import {mapGetters} from "vuex";

    export default {
        name: "Orders",
        components: {
            PayDatePrompt,
            VueBootstrap4Table,
            'v-select': vSelect
        },
        data() {
            return {
                activeDownloadMergedDocumentPrompt: false,
                selectedOrderType: { "id": 7, "name": "Rechnung", "invoice_type": "invoice", "is_cancellation": 0 },
                orderTypes: [],
                selectedData: [],
                data: [],
                actions: [
                    {
                        btn_text: "Hinzufügen",
                        router_link: '/invoices/add',
                        color: 'success'
                    }
                ],
                columns: [
                    {
                        label: "Status",
                        name: "status",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: true,
                        width: '240px',
                        slot_name: "status",
                        filter: {
                            type: 'select',
                            options: this.statusOptions,
                            mode: 'multi',
                            value: [{"value": 1, "label": "Entwurf"}, {"value": 2, "label": "Offen"}]
                        },
                        initial_sort: true,
                        initial_sort_order: 'asc',
                    },
                    {
                        label: "Beleg-Nr.",
                        width: '160px',
                        name: "invoice_number",
                        filter: {
                            type: "simple",
                            placeholder: "Suchen..."
                        },
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: true,
                    },
                    {
                        label: "Kunde",
                        name: "client.company_name",
                        filter: {
                            type: "simple",
                            placeholder: "Suchen..."
                        },
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: true,
                        width: '25%',
                    },
                    {
                        label: "Title",
                        name: "title",
                        sort: false,
                        filter: {
                            type: "simple",
                            placeholder: "Suchen..."
                        },
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        width: '25%',
                        showCol: true,
                    },
                    {
                        label: "Datum",
                        name: "order_date",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: true,
                        formatter: this.formatDateTime,
                        width: '220px',
                        initial_sort: true,
                        initial_sort_order: 'desc',
                        filter: {
                            type: 'date',
                            mode: 'range'
                        },
                    },
                    {
                      label: "Leistungsdatum (Ende)",
                      name: "period_end",
                      sort: true,
                      row_text_alignment: 'text-left',
                      column_text_alignment: 'text-left',
                      showCol: false,
                      formatter: this.formatDateTime,
                      width: '220px',
                      initial_sort: true,
                      initial_sort_order: 'desc',
                      filter: {
                        type: 'date',
                        mode: 'range'
                      },
                    },
                    {
                        label: "Fällig",
                        name: "overdue_date",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: false,
                        slot_name: "overdue",
                        width: '220px',
                        filter: {
                            type: 'date',
                            mode: 'range'
                        },
                    },

                    {
                        label: "Bezahlt am",
                        name: "paid_on_date",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: false,
                        slot_name: "paid_on_date",
                        width: '220px',
                        filter: {
                            type: 'date',
                            mode: 'range'
                        },
                    },
                    {
                        label: "Positionen",
                        name: "positions",
                        sort: false,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: false,
                        slot_name: "positions",
                        width: '220px',
                        filter: {
                            type: "simple",
                            placeholder: "Suchen..."
                        },

                    },
                    {
                        label: "Betrag",
                        name: "sum_netto",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        formatter: PriceHelper.formatCurrency,
                        width: '10%',
                        showCol: true,
                    },
                    {
                        label: "Betrag brutto",
                        name: "sum",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        formatter: PriceHelper.formatCurrency,
                        width: '10%',
                        showCol: false,
                    },
                    {
                        label: "Verrechnungssatz",
                        name: "adjusted_invoice_sum_netto",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        formatter: PriceHelper.formatCurrency,
                        width: '10%',
                        showCol: false,
                    },

                    {
                        label: "Notiz",
                        name: "notice",
                        sort: false,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        width: '10%',
                        showCol: false,
                    },
                    {
                        label: "Actions",
                        slot_name: "row_actions",
                        sort: false,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        column_classes: 'actions-column',
                        showCol: true,
                        width: '140px',

                    }],
                queryParams: {
                    sort: [{
                        caseSensitive: true,
                        name: "order_date",
                        order: "asc"
                    }],
                    filters: [],
                    global_search: "",
                    per_page: 10,
                    page: 1,
                },
                total_rows: 0,
                order_status: [],
                activePayedPrompt: false,
                payDatePromptOrderId: null,
                config: {
                    checkbox_rows: false,
                    show_refresh_button: false,
                    show_reset_button: false,
                    highlight_row_hover_color: '#f8f8f8',
                    server_mode: true,
                    global_search: {
                        visibility: false
                    },
                    per_page: 10
                }

            }
        },
        created() {

            if(this.userHasPermission('merge_documents')) {
                this.config.checkbox_rows = true;
            }
            if(this.userHasPermission('merge_documents')) {
                this.config.per_page = 10;
            }

            this.fetchData(true)
        },
        computed: {
            ...mapGetters(['userHasPermission', 'workspace']),
            requestParams() {
                let queryParams = QueryHelper.parseRequestParams(this.queryParams);


                queryParams.filter.push({
                    field: 'type.invoice_type',
                    value: 'invoice',
                    expression: 'exact'
                });

                if (this.selectedOrderType !== null && this.selectedOrderType.id !== 0) {
                    queryParams.filter.push({
                        field: 'type.id',
                        value: this.selectedOrderType.id,
                        expression: 'exact'
                    });
                }

                return queryParams;
            },
            paidStatusId() {
                const status = this.order_status.find(item => item.is_default_paid)
                if (!status) {
                    return null;
                }
                return status.id;
            },
            pageSum() {
                if(!this.data) {
                    return PriceHelper.formatCurrency(0);
                }

                const sum = this.data.reduce((acc, item) => {
                    return acc + item.sum_netto;
                    }, 0);

                return PriceHelper.formatCurrency(sum);
            }
        },
        methods: {
            mergeDocuments(){
                this.$vs.loading()
                ApiService.post('orders/mergeDocuments', this.selectedData).then((response) => {
                    this.$vs.loading.close()
                    if(response.data.status === 'success') {
                         this.$vs.notify({
                            title: 'Rechnungen erfolgreich zusammengeführt',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'success'
                        });
                        window.open(process.env.VUE_APP_API_URL+"document/mergedorder/"+this.workspace.id+"/invoice/"+response.data.data, '_blank')
                        return;

                    } else {
                        return this.$vs.notify({
                            title: 'Fehlgeschlagen',
                            text: response.data.message,
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    }
                }).catch((error) => {
                    this.$vs.loading.close()
                    return this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: error.message,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                })

            },
            csvExportOrders(){
                this.$vs.loading()
                ApiService.post('orders/csvExport', this.selectedData).then((response) => {
                    this.$vs.loading.close()
                    if(response.data.status === 'success') {
                         this.$vs.notify({
                            title: 'CSV Datei erfolgreich erstellt',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'success'
                        });
                        window.open(process.env.VUE_APP_API_URL+"document/"+this.workspace.id+"/csv-export/"+response.data.data, '_blank')
                        return;

                    } else {
                        return this.$vs.notify({
                            title: 'Fehlgeschlagen',
                            text: response.data.message,
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    }
                }).catch((error) => {
                    this.$vs.loading.close()
                    return this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: error.message,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                })

            },
            onSelectRows(payload) {
                this.selectedData = []
                this.selectedData = payload.selected_items
            },
            fetchInvoiceOrderTypes() {
                let queryParams = {
                    filter: [{
                        field: 'invoice_type',
                        value: 'invoice',
                        expression: 'exact'
                    }]
                };

                ApiService.get('order_types', {
                    params: queryParams,
                    paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
                }).then(response => {
                    this.orderTypes = response.data.result.result;

                    const allTypes =  {
                        id: 0,
                        name: 'Alle Belege',
                        is_cancellation: 0
                    };

                    this.orderTypes.unshift(allTypes)
                })
            },
            fetchData(initial = false) {
                this.$vs.loading();

                if (!initial) {
                    return this.fetchOrders();
                }

                this.fetchOrderStatus();

                this.fetchInvoiceOrderTypes();
            },
            fetchOrderStatus() {
                let queryParams= {
                    filter: [
                        {
                            field: 'invoice_type',
                            expression: 'exact',
                            value: 'invoice'
                        }
                    ]
                };

                return ApiService.get('order_status', {
                    params: queryParams,
                    paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
                }).then(response => {
                    this.order_status = response.data.result;
                    this.setStatusOptions();
                });
            },
            fetchOrders() {
                ApiService.get('orders', {
                    params: this.requestParams,
                    paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
                }).then(response => {
                    this.data = response.data.result;
                    this.total_rows = response.data.total;
                    this.$vs.loading.close()
                })
            },
            openPayDatePrompt(orderId) {
                this.payDatePromptOrderId = orderId;
                this.activePayedPrompt = true;
            },
            onPayDatePromptClose() {
                this.activePayedPrompt = false;
                this.fetchData();
            },
            formatDate(value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
            },
            onChangeQuery(queryParams) {
                this.queryParams = queryParams;
                this.fetchData();
            },
            onChangePerPage(value) {
                this.config.per_page = value;
            },
            orderStatusFormatter(value) {
                const status = this.getOrderStatusById(value);

                if (!status) {
                    return value;
                }

                return status.label;
            },
            overDueDateFormatter(date, status) {
                var a = moment();
                var b = moment(date);
                const diff = a.diff(b, 'days');

                if (status == 1) {
                    return null;
                }

                if (status == 3) {
                    return this.formatDate(date);
                }

                if (diff === 0) {
                    return `<span class="mg-label warning">Heute Fällig</span>`;
                }
                if (diff > 0) {
                    return `<span class="mg-label danger">${diff} Tage überfällig</span>`;
                }

                return this.formatDate(date);
            },

            getOrderStatusById(id) {
                id = parseInt(id);
                return this.order_status.find(item => item.id === id);
            },
            showEditButton(id) {
                const status = this.getOrderStatusById(id);

                if (!status) {
                    return false;
                }

                return status.is_draft === 1;
            },
            formatDateTime(value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
            },
            setStatusOptions() {
                let options = [];

                this.order_status.forEach((status) => {
                    options.push({
                        value: status.id,
                        label: status.name
                    })
                });

                this.columns[0].filter.options = options;
            },
            changeOrderType() {
                this.fetchData();
            },
            getDocumentUrl(order) {
                return `${process.env.VUE_APP_API_URL}document/${this.workspace.id}/order/${order.type.invoice_type}/${order.file}`;
            },
            getParentStatus(itemStatusId) {
                const itemStatus = this.order_status.find(item => item.id === parseInt(itemStatusId))

                if(!itemStatus.parent_id) {
                    return null;
                }

                const status = this.order_status.find(item => item.id === itemStatus.parent_id)

                if (!status) {
                    return null;
                }

                return status;
            },
        },
        mounted() {
        }
    }
</script>

<style scoped>

</style>